import React from 'react'
import {Resourceii, Resourcei} from './constant'

const Product0: React.FC<{changeIMenu: (i: number) => void}> = props => {

  const {changeIMenu} = props

  return <>
     {
            Resourcei.map(x =>  <div key={x.title} className='product-2' style={{
                ...x.left?{background: '#FBFCFD'}: {}
            }}>
            <div className='product-2-content'>
                <div className='product-2-content-item' style={{
                    top: 0,
                    ...x.left?{right: ''}: {left: 0},
                    width: '55%'
                    }}>
                    <div className='product-2-content-item-1'></div>
                    <div className='product-2-content-item-2 '>{x.title}</div>
                    
                    {
                        x.li.map((y: any) => <div key={y} className='product-2-content-item-3'>
                        <span className='product-2-content-item-3-dot'></span>
                        {y}
                    </div>)
                    }
                    <div className='product-2-content-item-4' onClick={() => changeIMenu(x.link)}>了解详情</div>
                </div>
                <div className='product-2-content-item' style={{
                    top: 0,
                    ...x.left?{left: 0}: {paddingLeft: 120},
                    width: '45%'
                }}>
                    <img style={{width: x.width}} src={x.img} alt="" />
                </div>
            </div>
        </div>)
        }
        <div className='product-3'>
            <div className='product-3-h1'>更值得托付的慧云班</div>
            <div className='product-3-content'>
                {
                    Resourceii.map(x =>  <div key={x.img} className='product-3-content-item'>
                    <img src={x.img} alt="" />
                    <div className='product-3-content-item-title'>{x.a}</div>
                    <div className='product-3-content-item-content'>
                    {x.b}
                    </div>
                </div>)
                }
            </div>
        </div>
        <div className='product-4'>
            <div className='product-4-h1'>串联全业务流程，打造一体化用工管理平台</div>
            <div className='product-4-h2'>以智能排班为闭环核心</div>
            <div className='product-4-content'>
                <img src="https://s.huiyunban.cn/1743158441704165376.png" />
            </div>
        </div>
  </>
}

export default Product0