export const Resource: any[] = [
  {
    h1: '关于慧云班',
    h2: '慧云班是一家专注于餐饮和零售企业的智能排班管理平台。通过先进的人工智能和大数据技术，根据⻔店的工时需求、业务量预测等因素，智能生成最优化的排班方案。不仅能够帮助企业减少成本浪费，还可以通过实现合理的工时安排，提高员工的工作积极性和满意度。我们深知员工的工作体验对企业的发展至关重要，因此致力于为企业提供更智能、更精细的排班管理方案，确保工作任务的平衡性、员工需求的满足性和成本的优化性。慧云班的解决方案将帮助企业更好地落地精益排班理念，实现运营的顺利和持续发展。',
    img: 'https://s.huiyunban.cn/1744265176615489536.png',
    left: 0
  },
  {
    h1: '劳动力管理一揽子解决方案',
    h2:'除了智能排班功能，慧云班还提供实时的考勤记录和工作时间统计，帮助企业掌握员工的工作状态和业务量情况。这有助于实现营业额和人时效的同步，有效提升企业的运营效率。慧云班整合了智能机器人功能，成为企业的AI店⻓助手。不仅提供店铺运营数据和趋 势分析报告，还通过机器人实时帮助店⻓进行决策制定和调控。我们提供的智能客服功能，可以实时解答咨询并提供个性化服务，实现高效沟通和深度管理。 ',
    img: 'https://s.huiyunban.cn/1744265048433364992.png',
    left: 1
  },
  {
    h1: '我们的愿景',
    h2: '慧云班秉承着与餐饮和零售企业成为紧密合作伙伴的使命，致力于为更多企业提供敏捷的智能排班管理解决方案。通过提供全面的排班管理工具和智能算法，帮助企业实现运营的顺利和持续发展。我们的目标是与企业一同应对挑战，提升工作效率，优化人力资源配置，并为员工提供更好的工作环境。慧云班将持续不断地提供创新的解决方案，助力企业在竞争激烈的市场中脱颖而出，并取得更大的成功。',
    img: 'https://s.huiyunban.cn/1744265814938226688.png',
    left: 0
  },
]