export const Resource = [
  {
    a: '公众号：',
    img: 'https://s.huiyunban.cn/1744239349500743680.png'
  },
  {
    a: '小程序：',
    img: 'https://s.huiyunban.cn/1744240755376918528.png'
  },
  {
    a: '咨询客服：',
    img: 'https://s.huiyunban.cn/1744240946259693568.png'
  }
]