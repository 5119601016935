import React from 'react';
import {Resource, Resource8, Resource9, Resource10} from './constant';

import Product1 from  './Product1';
import Product0 from './Product0';
import ProductCommon from './ProductCommon';
import './index.less';

const Product: React.FC<{iMeun: number; changeIMenu: (i: number) => void}> = props => {

    const {iMeun, changeIMenu} = props

    const MapResource: {[index: string]: any[]} = {
        2: Resource8,
        3: Resource9,
        4: Resource10
    }

    return <div className='product'>
        <div className='product-1'>
            <div className='product-1-content'>
                <div className='product-1-content-item'>{Resource[iMeun]?.a}</div>
                <div className='product-1-content-item'>{Resource[iMeun]?.b}</div>
            </div>
        </div>
       
        {iMeun === 0 && <Product0 changeIMenu={changeIMenu}/>}
       {iMeun === 1 && <Product1/>}
       {(iMeun === 2 || iMeun === 3 || iMeun === 4) && <ProductCommon resource={MapResource[iMeun]}/>}
    </div>
}

export default Product