import React from 'react';

import {Resourcei, Resourceii} from './constant';

import './index.less';

const Ai: React.FC = () => {
    return <div className='ai'>
        <div className='ai-1'>
            <div className='ai-1-content'>
                <div className='ai-1-content-item'>全流程咨询丨操作助手丨数据查询</div>
                <div className='ai-1-content-item'>GPT赋能 全场景AI</div>
            </div>
        </div>
        <div className='ai-2'>
            <div className='ai-2-h1'>
                赋能企业各角色 助力数字化转型
            </div>
            <div className='ai-2-content'>
                {
                    Resourcei.map(x => <div key={x.a} className='ai-2-content-item'>
                    <div className='ai-2-content-item-title'>
                        <img src={x.img} />
                        {x.a}
                    </div>
                    {
                        x.b.map(y =>  <div key={y} className='ai-2-content-item-d'>
                        <img src="https://s.huiyunban.cn/1744177540571860992.png"/>
                        {y}
                    </div>)
                    }
                </div>)
                }
            </div>
        </div>
        <div className='ai-3'>
            <div className='ai-3-h1'>
                赋能企业各角色 助力数字化转型
            </div>
            <div className='ai-3-content'>
                
                {
                    Resourceii.map(x => <div key={x.a} className='ai-3-content-item'>
                    <div className='ai-3-content-item-h1'>
                        {x.a}
                    </div>
                    <div className='ai-3-content-item-h2'>{x.b}</div>
                    <div>
                        {
                            x.c.map(y => <div key={y} className='ai-3-content-item-h3'>{y}</div>)
                        }
                    </div>
                </div>)
                }
            </div>
        </div>
    </div>
}

export default Ai