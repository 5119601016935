import React, {Fragment} from 'react';

import {Space, Divider} from 'antd';

import {Resourceiii, Resourceiiii, Resource7} from './constant';

const Product1: React.FC = () => {
  return <>
     <div className='product-5'>
            <div className='product-5-h1'>智能<span className='product-5-h1-span'>自动化</span>排班流</div>
            <div className='product-5-content'>
                {
                    Resourceiii.map(x => <Fragment key={x.a}>
                    <div className='product-5-content-item'>
                        <div className='product-5-content-item-title'>{x.a}</div>
                        <div className='product-5-content-item-content'>
                        {x.b}
                        </div>
                    </div>
                    {x.img && <img src={x.img}/>}
                    </Fragment>)
                }
            </div>
        </div>
        {
            Resourceiiii.map(x =>  <div
                className='product-6'
                key={x.h1}
                style={{background: x.left?'#F6F9FF': '#fff' }}
            >
            <div className='product-6-content'>
                <div className='product-6-content-item' style={
                    {
                        width: '58%',
                        ...x.left?{left: 0}: {right: 0}
                    }
                    }>
                    <div className='product-6-content-item-h1'>{x.h1}</div>
                    <div className='product-6-content-item-h2'>{x.h2}</div>
                    <div className='product-6-content-item-content'>
                        {
                            x.content.map((y: any) => <div className='product-6-content-item-content-item' key={y.a}>
                            <div className='product-6-content-item-content-item-title'>{y.a}</div>
                            <div className='product-6-content-item-content-item-text'>{y.b}</div>
                            {
                                x.left === 1 && <img style={{left: 0}} src="https://s.huiyunban.cn/1743186751179984896.png" alt="" />
                            }
                            {
                                x.left === 0 &&  <img style={{right: 0}} src="https://s.huiyunban.cn/1743198687812653056.png" alt="" />
                            }
                        </div>)
                        }
                    </div>
                </div>
                <div className='product-6-content-item' style={{
                    width: '42%',
                    ...x.left?{right: 0}: {left: 0}
                    }}>
                    <img src={x.img} style={{
                        ...x.left?{right: 0}: {}
                    }} />
                </div>
            </div>
        </div>)
        }
        <div className='product-7'>
            <div className='product-7-h1'>减少人力成本 提升人时效能</div>
            <div className='product-7-h2'>所有角色均受益匪浅</div>
            <div className='product-7-content'>

                {
                    Resource7.map(
                        x => <div className='product-7-content-item' key={x.b}>
                        <div className='product-7-content-item-content'>
                            <div className='product-7-content-item-content-title'>
                            <Space split={<Divider type="vertical" />} size={0}>
                                {
                                    x.a.map((y: any) => <span key={y}>{y}</span>)
                                }
                            </Space>
                            </div>
                            <div className='product-7-content-item-content-text'>{x.b}</div>
                        </div>
                    </div>
                    )
                }
            </div>
        </div>
  </>
}

export default Product1