import React from 'react';

import {Resource} from './constant';
import './index.less';

const Footer: React.FC = () => {
  return <div className='footer'>
    <div className='footer-1'>
      <div className='footer-1-content'>
        <div className='footer-1-content-h1'>体验更先进的用工管理方式</div>
        <div className='footer-1-content-h2'>免费试用，最快次日安排演示</div>
        <div className='footer-1-content-h3' onClick={() => window.open('https://jinshuju.net/f/mwmbSm')}>预约演示</div>
      </div>
    </div>
    <div className='footer-2'>
      <div className='footer-2-content'>
        <div className='footer-2-content-item-1'>
          <div className='footer-2-content-item-1-logo'>
            <img src="https://s.huiyunban.cn/1744232608314691584.png" alt="" />
          </div>
          <div className='footer-2-content-item-1-h1'>更轻松更智能地提升人效，为您创造更多劳动力价值</div>
          <div className='footer-2-content-item-1-content'>
            {
              Resource.map(x => <div key={x.a} className='footer-2-content-item-1-content-i'>
              <div className='footer-2-content-item-1-content-i-title'>{x.a}</div>
              <img src={x.img}/>
            </div>)
            }
          </div>
        </div>
        <div className='footer-2-content-item-2'>
          <div className='footer-2-content-item-2-h1'>
            联系我们
          </div>
          <div className='footer-2-content-item-2-li'>
            <img src="https://s.huiyunban.cn/1744244946522607616.png" style={{width: 14, height: 18}}/>
            上海市浦东新区泳耀路300号东方体育中心
          </div>
          <div className='footer-2-content-item-2-li'>
            <img src="https://s.huiyunban.cn/1744245198881296384.png"/>
            <img src="https://s.huiyunban.cn/1744591472868462592.png" style={{width: 100, height: 18}}/>
          </div>
          <div className='footer-2-content-item-2-li'>
            <img src="https://s.huiyunban.cn/1744245417974960128.png"/>
            9:00-18:00
          </div>
          <div className='footer-2-content-item-2-li'>
            <img src="https://s.huiyunban.cn/1744245619070865408.png"/>
            help@huiyunban.cn
          </div>
        </div>
        <div className='footer-2-content-item-3'>
          <div className='footer-2-content-item-2-h1'>
          预约演示
          </div>
          <div className='footer-2-content-item-2-li'>
            <img src="https://s.huiyunban.cn/1744248970890186752.png"/>
            <a target="_blank" href="https://jinshuju.net/f/mwmbSm">慧云班专属服务</a>
          </div>
        </div>
      </div>
    </div>
    <div className='footer-3'>
      上海慧云班网络科技有限公司 &#x3000;  <a target="_blank" href="https://beian.miit.gov.cn">沪ICP备2023011842号-2</a>
    </div>
  </div>
}

export default Footer