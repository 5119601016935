import React from 'react';

const ProductCommon: React.FC<{resource: any[]}> = props => {

  const {resource} = props

  return <>
     {
            resource.map(x =>  <div
                className='product-6'
                key={x.h2}
                style={{background: x.left?'#F6F9FF': '#fff' }}
            >
            <div className='product-6-content'>
                <div className='product-6-content-item' style={
                    {
                        width: '58%',
                        ...x.left?{left: 0}: {right: 0}
                    }
                    }>
                    <div className='product-6-content-item-h1'>{x.h1}</div>
                    <div className='product-6-content-item-h2'>{x.h2}</div>
                    <div className='product-6-content-item-content'>
                        {
                            x.content.map((y: any) => <div className='product-6-content-item-content-item' key={y.a}>
                            <div className='product-6-content-item-content-item-title'>{y.a}</div>
                            <div className='product-6-content-item-content-item-text'>{y.b}</div>
                            {
                                x.left === 1 && <img style={{left: 0}} src="https://s.huiyunban.cn/1743186751179984896.png" alt="" />
                            }
                            {
                                x.left === 0 &&  <img style={{right: 0}} src="https://s.huiyunban.cn/1743198687812653056.png" alt="" />
                            }
                        </div>)
                        }
                    </div>
                </div>
                <div className='product-6-content-item' style={{
                    width: '42%',
                    ...x.left?{right: 0}: {left: 0}
                    }}>
                    <img src={x.img} style={{
                        ...x.left?{right: 0}: {}
                    }} />
                </div>
            </div>
        </div>)
        }
  </>
}

export default ProductCommon