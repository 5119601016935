import React, {useState} from 'react';
import { Route, Routes } from 'react-router-dom';
import Header from './Layout/Header';
import Footer from './Layout/Footer';
import Home from './Pages/Home';
import Product from './Pages/Product';
import Ai from './Pages/Ai';
import Service from './Pages/Service';
import My from './Pages/My';
import './App.css';

function App() {

  const [iMeun, setIMeun] = useState(0);
  const [iiMenu, setIIMenu] = useState(0);

  const changetitle = (i: number) => {
    setIMeun(i);
  }

  const changeIMenu = (i: number) => {
    setIIMenu(i)
  }

  return (
    <div className="App">
      <Header changetitle={changetitle} changeIMenu={changeIMenu} iiMenu={iiMenu}/>
      <Routes>
        <Route path="/" element={<Home changeIMenu={changeIMenu} />} />
        <Route path="/home" element={<Home changeIMenu={changeIMenu} />} />
        <Route path="/product" element={<Product iMeun={iMeun} changeIMenu={changeIMenu} />} />
        <Route path="/ai" element={<Ai />} />
        <Route path="/service" element={<Service />} />
        <Route path="/my" element={<My />} />
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
