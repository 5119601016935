import React, { useCallback, useEffect, useState } from 'react';
import { Dropdown } from 'antd';
import { useNavigate } from 'react-router-dom';

import './index.less';

const Header: React.FC<{ changetitle: (key: number) => void; iiMenu: number; changeIMenu: (key: number) => void }> = props => {
  const { changetitle,  iiMenu, changeIMenu} = props;
  const navigate = useNavigate();
  const [key, setKey] = useState('/home');
  const [iMeun, setIMenu] = useState(0);

  const selectTitle =(i: number) => {
    if (key !== '/product') {
      onChange('/product')
    }
    changetitle(i);
    setIMenu(i);
  }

  const items: any = [
    {
      label: <span style={{color: iMeun === 1 ?'#0256FF':''}} onClick={() => selectTitle(1)}>智慧排班</span>,
      key: 1,
    },
    {
      label: <span style={{color: iMeun === 2 ?'#0256FF':''}} onClick={() => selectTitle(2)}>人事管理</span>,
      key: 2,
    },
    {
      label: <span style={{color: iMeun === 3 ?'#0256FF':''}} onClick={() => selectTitle(3)}>假勤管理</span>,
      key: 3,
    },
    {
      label: <span style={{color: iMeun === 4 ?'#0256FF':''}} onClick={() => selectTitle(4)}>薪酬管理</span>,
      key: 4,
    }
  ];

  const onChange = (_key: string) => {
    if (key !== '/product') {
      setIMenu(-1)
    }
    navigate(_key)
    setKey(_key)
  }

  useEffect(() => {
    navigate('/home')
  }, [])

  useEffect(() => {
    console.log(iiMenu, 'iiMenu')
    iiMenu> 0 && selectTitle(iiMenu)
  }, [iiMenu])

  return <div className='header'>
    <img src="https://s.huiyunban.cn/1742058338461421568.png" alt="" />
    <div className='header-nav'>
      <span className={key === '/home' ? 'header-nav-active' : ''} onClick={() => onChange('/home')}>首页</span>
      <span className={key === '/product' ? 'header-nav-active' : ''}>
        <Dropdown
          menu={{ items }}
          placement="bottom"
          overlayStyle={{ top: 96, width: 140, textAlign: 'center' }}>
            
          <a onClick={(e) => {
            e.preventDefault()
            changetitle(0)
            setIMenu(-1)
            onChange('/product')
            changeIMenu(-1)
          }}>
            产品
          </a>
        </Dropdown>
      </span>
      <span className={key === '/ai' ? 'header-nav-active' : ''} onClick={() => onChange('/ai')}>AI</span>
      <span className={key === '/service' ? 'header-nav-active' : ''} onClick={() => onChange('/service')}>服务</span>
      <span className={key === '/my' ? 'header-nav-active' : ''} onClick={() => onChange('/my')}>我们</span>
    </div>
    <div className='header-show' onClick={() => window.open('https://jinshuju.net/f/mwmbSm')}>免费演示</div>
  </div>
}

export default Header