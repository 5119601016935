import React, { useState } from 'react';
import { Carousel } from 'antd';

import './index.less';

const Home: React.FC<{changeIMenu: (i: number) => void}> = props => {

    const {changeIMenu} = props
    const [current, setCurrent] = useState<number>(0)

    const onChange = (currentSlide: number, next: number) => {
        // console.log(currentSlide, 'currentSlide', next)
        setCurrent(next)
    }
    const resource: any[] = [
        {
            a: '智能排班',
            b: <div>智能<span className='home-carousel-item-ai'>AI</span>助力</div>,
            c: '自动生成最优排班方案',
            img: 'https://s.huiyunban.cn/1742393472024842240.png',
            link: 1
        },
        {
            a: '薪酬管理',
            b: <div>打通<span className='home-carousel-item-ai'>考勤</span>数据</div>,
            c: '准确、快速、自动生成薪资表',
            img: 'https://s.huiyunban.cn/1742413594630295552.png',
            link: 4
        },
        {
            a: '人事管理',
            b: '灵活定制 ',
            c: '一体化用工管理全流程',
            img: 'https://s.huiyunban.cn/1742413779632656384.png',
            link: 2
        },
        {
            a: '假勤管理',
            b: '多维度报表 ',
            c: '科学更规范的考勤管理',
            img: 'https://s.huiyunban.cn/1742413932145938432.png',
            link: 3
        }
    ]
    const contentStyle: React.CSSProperties = {
        margin: 0,
        height: '700px',
        color: '#fff',
        backgroundImage: `url(${resource[current].img})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    };
    const resourcei: any[] = [
        {
            title: '智能助理',
            a: '协助排班',
            b: '自动考勤修正+报盘',
            c: '快捷员工管理',
            d: '通岗能力自解析',
            ai: '40%',
            bi: '60%',
            ci: '50%',
            di: '50%',
        },
        {
            title: '可视化运营诊断',
            a: '数据实时生成',
            b: '数据自动预警+报盘',
            c: '自定义数据维度',
            d: '报表随时导出',
            ai: '45%',
            bi: '55%',
            ci: '60%',
            di: '40%',
        },
        {
            title: '行业咨询24小时在线',
            a: '财务管理',
            b: '销售与营销策略',
            c: '人力资源管理',
            d: '领导与沟通技巧',
            ai: '50%',
            bi: '50%',
            ci: '50%',
            di: '50%',
        },
        {
            title: '系统/员工服务机器人',
            a: '自助给班建议',
            b: '快速对接3方接口',
            c: '导师职场建议',
            d: '智能客服随时服务',
            ai: '50%',
            bi: '50%',
            ci: '50%',
            di: '50%',
        },
    ]

    const resourceii: any[] = [
        {
            a: 30,
            b: '+',
            c: '专业开发人员'
        },
        {
            a: 8,
            b: '+',
            c: 'Saas行业资深专家'
        },
        {
            a: 1,
            b: '月',
            c: '月均/1次迭代频率'
        },
        {
            a: 99,
            b: '%',
            c: '客户好评度'
        }
    ]
    return <div className='home'>
        <div className='home-1'>
            <div className='home-carousel'>
                <Carousel beforeChange={onChange} autoplay>
                    {
                        resource.map(x => <div key={x.a}>
                            <div style={contentStyle} >
                                <div className='home-carousel-item'>
                                    <div className='home-carousel-item-row home-carousel-item-title'><span>{x.a}</span></div>
                                    <div className='home-carousel-item-row'>{x.b}</div>
                                    <div className='home-carousel-item-row'>{x.c}</div>
                                    <div className='home-carousel-item-spot'></div>
                                    <div className='home-carousel-item-button' onClick={() => {
                                        changeIMenu(x.link)
                                    }}>了解详情</div>
                                </div>
                            </div>
                        </div>)
                    }
                </Carousel>
            </div>
        </div>
        <div className='home-2'>
            <div className='home-2-h1'>服务支持</div>
            <div className='home-2-h2'>专业丨标准丨高效</div>
            <div className='home-2-content'>
                <div className='home-2-content-item' >
                    <div className='home-2-content-item-h1'>方案定制</div>
                    <div className='home-2-content-item-h2'>深入调研+定制方案+售前咨询</div>
                </div>
                <div className='home-2-content-item' >
                    <div className='home-2-content-item-h1'>实施跟进</div>
                    <div className='home-2-content-item-h2'>需求调研+落地实施+测试上线</div>
                </div>
                <div className='home-2-content-item' >
                    <div className='home-2-content-item-h1'>客户成功</div>
                    <div className='home-2-content-item-h2'>专属对接+迭代需求+长期培训</div>
                </div>
            </div>
            <img src="https://s.huiyunban.cn/1742451199661051904.png" alt="" />
        </div>
        <div className='home-3'>
            <div className='home-3-h1'>下一代的用工管理方式</div>
            <div className='home-3-h2'>操作丨咨询丨管理丨预测全面接入AIGC</div>
            <div className='home-3-content'>
                {
                    resourcei.map(x => <div key={x.title} className='home-3-content-item' >
                        <div className='home-3-content-item-h1'>{x.title}</div>
                        <div className='home-3-content-item-col' style={{ width: x.ai }}>
                            <span className='home-3-content-item-col-dot'></span>
                            {x.a}
                        </div>
                        <div className='home-3-content-item-col' style={{ width: x.bi }}>
                            <span className='home-3-content-item-col-dot'></span>
                            {x.b}
                        </div>
                        <div className='home-3-content-item-col' style={{ width: x.ci }}>
                            <span className='home-3-content-item-col-dot'></span>
                            {x.c}
                        </div>
                        <div className='home-3-content-item-col' style={{ width: x.di }}>
                            <span className='home-3-content-item-col-dot'></span>
                            {x.d}
                        </div>
                    </div>
                    )
                }
            </div>
        </div>
        <div className='home-4'>
            <div className='home-4-content'>
                {
                    resourceii.map(x => <div key={x.a} className='home-4-content-item'>
                    <div>
                        <span className='home-4-content-item-n'>{x.a}</span>
                        <span className='home-4-content-item-r'>{x.b}</span>
                    </div>
                    <div className='home-4-content-item-b'>{x.c}</div>
                </div>)
                }
            </div>
        </div>
    </div>
}

export default Home