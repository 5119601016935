export const Resourceii: any[] = [
  {
      img: 'https://s.huiyunban.cn/1743152092094140416.png',
      a: '全面接入AIGC',
      b: '操作、咨询、管理融合gpt，全天候专家级助手陪伴，全场景跨越式效率提升'
  },
  {
      img: 'https://s.huiyunban.cn/1743154338252984320.png',
      a: '安全可靠',
      b: '所有流量经WAF应用防火墙恶意特征识别及防护，避免网站服务器被恶意入侵，保障业务的核心数据安全'
  },
  {
      img: 'https://s.huiyunban.cn/1743157062415683584.png',
      a: '多活容灾',
      b: '具备分钟级恢复能力，横向支持容灾架构的上线、运维、演练、切流，升级到下线。纵向支持业务流量的全链路管理'
  },
  {
      img: 'https://s.huiyunban.cn/1743155982315294720.png',
      a: '对接接口丰富',
      b: '提供Open API开放接口，自由对接企业微信、钉钉、酷学院等各类型三方平台'
  },
  {
      img: 'https://s.huiyunban.cn/1743156142361546752.png',
      a: '数据加密',
      b: '对用户身份证，手机号，银行卡，住址等敏感信息通过使用加密服务，将敏感数据加密保护，对抗数据明文泄露威胁'
  },
  {
      img: 'https://s.huiyunban.cn/1743155843592884224.png',
      a: '弹性高效',
      b: '支持自动弹性伸缩，从容应对突发业务，无须手动干预，超强的业务处理能力，轻松应对万级并发，性能优异，稳如磐石'
  }
]

export const Resourcei: any[] = [
  {
     title: '新一代智能排班',
     li: [
      '独家AI算法应用，多套模型快速匹配业务场景，越用越精准',
      '支持按订单、按营业额、产品类目等多种颗粒度，极大提升预测精准度',
      '通岗能力可视化，结合可用工时，确保人岗高度匹配',
      '多维度人时效能报表，运营状况随时掌握'
     ],
     left: 1,
     img: 'https://s.huiyunban.cn/1745679974116888576.png',
     width: 413,
     link: 1
  },
  {
      title: '人事管理',
      li: [
          '多类型考勤结果异常提醒，随时修改调整，考勤结果更精准',
          '使用钉钉、企微或自研人事管理系统，一键同步，人事不复杂，操作做减法',
          '健康证、合同到期、转正等等信息，实时提醒，不用再担心遗漏'
      ],
      left: 0,
      img: 'https://s.huiyunban.cn/1745680496639086592.png',
      width: 413,
      link: 2
  },
  {
      title: '假勤管理',
      li: [
          '多类型考勤结果异常提醒，随时修改调整，考勤结果更精准',
          '假期类型支持自定义设置，单个/批量增减更随心',
          '考勤数据多维度可视化，可任意导出导出，助力精细化劳动力管理',
          'OA审批流灵活自定义，员工管理更高效'
      ],
      left: 1,
      img: 'https://s.huiyunban.cn/1745680693976895488.png',
      width: 413,
      link: 3
  },
  {
      title: '薪酬管理',
      li: [
          '薪资规则灵活自定，不同人群匹配对应计薪规则，一次配置更省时省力',
          '打通考勤，自动生成工资表，让计算薪酬从4天降到半小时',
          '结算周期灵活可配，支持日结、周结、月结等',
          '工资模板客制化，支持不同员工类型不同模板'
      ],
      left: 0,
      img: 'https://s.huiyunban.cn/1745688597786267648.png',
      width: 413,
      link: 4
  }
]

export const Resource: any[] = [
  {
    a: '智能驱动经营，精益提升效能',
    b: '慧云班人效云'
  },
  {
    a: '深度融合AI算法，并结合实际业务场景，一键生成更合理更人性的排班计划，轻松降本提人效',
    b: '新一代智能排班'
  },
  {
    a: '覆盖员工全生命周期 更轻便 更好用',
    b: '人事管理'
  },
  {
    a: '考勤计工时自动化 降本增效看得见',
    b: '假勤管理'
  },
  {
    a: '规则化繁为简 算薪全自动',
    b: '薪酬管理'
  }
]

export const Resourceiii: any[] = [
  {
    a: '导入历史数据',
    b: '提供固定班次排班、划线自主排班、批量修改排班、一天多任务排班等多种方式。',
    img: 'https://s.huiyunban.cn/1743170779110969344.png'
  },
  {
    a: '数据分析和优化',
    b: '利用历史数据和实时数据进行深入分析、了解销售趋势、客流量和顾客需求等因素，并根据这些数据优化营业策略和人岗匹配管理。',
    img: 'https://s.huiyunban.cn/1743170779110969344.png'
  },
  {
    a: '预测营业额/劳动力',
    b: '预测未来一周营业额数据，合理规划人员数量和工作时间，使用人力需求预测模型来估计未来需要的劳动力数量和类型。',
    img: 'https://s.huiyunban.cn/1743170779110969344.png'
  },
  {
    a: '精益排班',
    b: '根据预测的劳动力需求和人岗匹配结果，制定合理的排班计划，最大程度地满足业务需求并避免人力资源浪费。',
    img: ''
  }
]

export const Resourceiiii: any[] = [
  {
    h1: '结合AI算法+业务数据，把降本增效落到实处',
    h2: '科学预测，精准排班一键生成',
    content: [
      {
        a: '深度机器学习',
        b: '独家AI算法应用，多套模型快速匹配业务场景越用越精准。'
      },
      {
        a: '多维业务数据',
        b: '基于历史业务数据支持按订单、按营业额等多种颗粒度,极大提升预测精准度。'
      },
      {
        a: '操作流程极简',
        b: '90%结果系统生成,10%排班经理微调，显著降低工作量,减少人为主观预测。'
      }
    ],
    img: 'https://s.huiyunban.cn/1744670747407683584.png',
    left: 0
  },
  {
    h1: '员工技能熟练度一目了然，提升员工成就感',
    h2: '通岗能力可视化，结合可用工时，确保人岗高度匹配',
    content: [
      {
        a: '能力地图',
        b: '行业通用ILUO4阶能力图谱，逐级提升，让每位员工持续成长。'
      },
      {
        a: '数据图表',
        b: '多维度通岗统计，实时查看分析报表，支持部门、员工能力计划。'
      },
      {
        a: '打通工作站',
        b: '多能力集合，固定变通岗，高峰低谷灵活安排提升效率。'
      }
    ],
    img: 'https://s.huiyunban.cn/1744283201083281408.png',
    left: 1
  },
  {
    h1: '自由调度，实现岗位共享',
    h2: '灵活自主，多种排班方式任选，手机电脑均支持',
    content: [
      {
        a: '排班方式多样',
        b: '固定班次、划线、批量修改、多时段排班等多种方式自由选择。'
      },
      {
        a: '合规提醒',
        b: '自主设定工时/休息提醒规则，让排班更合规'
      },
      {
        a: '支调管理',
        b: '跨门店/地区人员调度一键申请，成本核算一目了然,提升人力利用率'
      }
    ],
    img: 'https://s.huiyunban.cn/1744671060285984768.png',
    left: 0
  },
  {
    h1: '人时营业额随时查看，助力精细化管理',
    h2: '多维度人时效能报表，运营状况随时掌握',
    content: [
      {
        a: '数据报盘',
        b: '支持分时报盘/按天报盘，也可打通pos系统自动报盘。'
      },
      {
        a: '人时统计',
        b: '门店人时趋势可视化,高峰低谷时段人力配置更合理。'
      },
      {
        a: '排行统计',
        b: '同时对比多门店，可按人力成本率或人时效排名。'
      }
    ],
    img: 'https://s.huiyunban.cn/1744283627174236160.png',
    left: 1
  }
]

export const Resource7: any[] = [
  {
    a: ['班次随时查', '给班自己定', '消息及时收'],
    b: '一线员工'
  },
  {
    a: ['班次轻松排', '工时随时看', '通岗及时升'],
    b: '门店管理员'
  },
  {
    a: ['人时随心查', '排名直观看', '加班即时审'],
    b: '运营管理员'
  }
]

export const Resource8: any[] = [
  {
    h1: '',
    h2: '组织管理更灵活',
    content: [
      {
        a: '架构管理',
        b: '支持多层级组织管理，灵活配置主、副负责人'
      },
      {
        a: '三方同步',
        b: '支持企微、钉钉等人事管理平台对接，工作习惯0影响。'
      },
      {
        a: '岗级管理',
        b: '自由配置岗位、职务类别和名称，设定不同层级和定级标准。'
      }
    ],
    img: 'https://s.huiyunban.cn/1744987508514492416.png',
    left: 0
  },
  {
    h1: '',
    h2: '员工管理更高效',
    content: [
      {
        a: '入转调离',
        b: '批量/单个修改，进度随时调整，提升人事效率。'
      },
      {
        a: '档案字段',
        b: '30多种现成字段，可另自行添加，线上员工信息库更丰富。'
      },
      {
        a: '信息提醒',
        b: '转正、合同、健康证到期提醒，重要事项不耽误。'
      }
    ],
    img: 'https://s.huiyunban.cn/1744988641265979392.png',
    left: 1
  },
  {
    h1: '',
    h2: '部门管理更人性',
    content: [
      {
        a: '组织类型',
        b: '组织类型可分部门和门店，区分清晰更易管控。'
      },
      {
        a: '用工设置',
        b: '用工类型和招聘来源支持增删改，数据维度更丰富。'
      },
      {
        a: '权限设置',
        b: '自定义配置不同查看/编辑权限对应角色，让管理更专注更安全。'
      }
    ],
    img: 'https://s.huiyunban.cn/1744989152945901568.png',
    left: 0
  }
]

export const Resource9: any[] = [
  {
    h1: '',
    h2: '规则设置更自主',
    content: [
      {
        a: '考勤方式多样',
        b: '支持企微、钉钉等3方数据源对接，也可自由设置打卡地点和规则，使用慧云班小程序打。'
      },
      {
        a: '规则维度灵活',
        b: '考勤周期、工时设置、打卡开始/结束时间均可自主配置，与业务场景无缝衔接。'
      },
      {
        a: '异常规则自主',
        b: '迟到早退惩罚方式多样，支持按分钟数、次数、记事假、记旷工等多种组合。'
      }
    ],
    img: 'https://s.huiyunban.cn/1744279131538853888.png',
    left: 0
  },
  {
    h1: '',
    h2: '考勤管理更便捷',
    content: [
      {
        a: '考勤数据详实',
        b: '可查看每日所有员工考勤细则，7种人员状态展示，支调员工可单独筛选。'
      },
      {
        a: '异常考勤调整',
        b: '支持随时调整工时及申请加班和审批，设置封存节点，异常考勤情况不再有困扰。'
      },
      {
        a: '统计维度丰富',
        b: '月考勤数据按明细、汇总、部门分列，便于统计对比，支持导出表格'
      }
    ],
    img: 'https://s.huiyunban.cn/1744279344928264192.png',
    left: 1
  },
  {
    h1: '',
    h2: '假期管理更合理',
    content: [
      {
        a: '假期类型自定',
        b: '默认6种常见假期类型,更多类型可自行配置添加。'
      },
      {
        a: '编辑更加便捷',
        b: '页面直接快速添加，同时支持在线表格批量编辑、发放。'
      },
      {
        a: '修改记录可查',
        b: '余额变动记录随时可看,操作档案即时保存。'
      }
    ],
    img: 'https://s.huiyunban.cn/1744280015912046592.png',
    left: 0
  }
]

export const Resource10: any[] = [
  {
    h1: '',
    h2: '薪资规则自定义',
    content: [
      {
        a: '字段自定义',
        b: '默认10余种字段，可另行增加特定字段，满足企业实际所需。'
      },
      {
        a: '规则自定义',
        b: '支持按工时、在职时长等多种方式算工资项，减少每月重复工作量。'
      },
      {
        a: '薪资组自定义',
        b: '不同薪资组可设置不同算薪逻辑和规则，批量归类算薪更简易。'
      }
    ],
    img: 'https://s.huiyunban.cn/1744281223196315648.png',
    left: 0
  },
  {
    h1: '',
    h2: '薪资表自动生成',
    content: [
      {
        a: '考勤数据打通',
        b: '自动关联排班和考勤数据，无需2次比对。'
      },
      {
        a: '算薪逻辑清晰',
        b: '支持多种拆分逻辑，并在页面做详细说明，算薪过程一目了然。'
      },
      {
        a: '工资结果生成',
        b: '薪资一键导出，如有修改可删除后多次导出。'
      }
    ],
    img: 'https://s.huiyunban.cn/1745674505172291584.png',
    left: 1
  },
  {
    h1: '',
    h2: '薪资表灵活编辑',
    content: [
      {
        a: '薪资表按组分',
        b: '不同薪资组生成不同详情，编辑更有针对性也更简便。'
      },
      {
        a: '支持批量编辑',
        b: '工资表项目支持在线表格编辑，支持拖曳复制等快速操作。'
      },
      {
        a: '支持线下编辑',
        b: '双向导出导入功能，2次编辑更加顺手。'
      }
    ],
    img: 'https://s.huiyunban.cn/1744282134425636864.png',
    left: 0
  }
]