import React from 'react';

import {Resource} from './constant';
import './index.less';

const Service: React.FC =() => {
    return <div className='service'>
       <div className='service-1'>
            <div className='service-1-content'>
                <div className='service-1-content-item'>标准化丨流程化丨模块化丨数据化</div>
                <div className='service-1-content-item'>服务支持</div>
            </div>
        </div>
        {
            Resource.map(x =>  <div
                className='service-2'
                key={x.img}
                style={{background: x.left?'#F6F9FF': '#fff' }}
            >
            <div className='service-2-content'>
                <div className='service-2-content-item' style={
                    {
                        width: '55%',
                        ...x.left?{left: 0}: {right: 0},
                        marginTop: 40
                    }
                    }>
                    <div className='product-6-content-item-h1'></div>
                    <div className='product-6-content-item-h2'>{x.h1}</div>
                   
                    {
                        x.a.map((y: any) => <div key={y} className='service-2-content-item-li'>
                        <div className='service-2-content-item-li-dot'></div>
                        {y}
                    </div>)
                    }
                </div>
                <div className='service-2-content-item' style={{
                    width: '45%',
                    ...x.left?{right: 0}: {left: 0}
                    }}>
                    <img src={x.img} style={{
                        ...x.left?{right: 0}: {}
                    }} />
                </div>
            </div>
        </div>)
        }
    </div>
}

export default Service