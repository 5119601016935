export const Resource: any[] = [
  {
    h1: <><span style={{color: '#0256FF'}}>售前</span>方案定制</>,
    a: [
      '「深入调研」项目经理基于企业实际业务场景和公司背景、运营现状做深入调研',
      '「方案输出」理解把握企业需求和期望，为客户搭建劳动力管理信息化解决方案',
      '「咨询服务」快速响应客户的疑问和需求，依托行业经验，实时分析和解决客户业务问题'
    ],
    img: 'https://s.huiyunban.cn/1744265412113076224.png',
    left: 0
  },
  {
    h1: <>实施部署——<span style={{color: '#0256FF'}}>实施前</span></>,
    a: [
      '梳理客户功能逻辑，确认培训计划书',
      '准备基础设置信息，导入员工信息',
      '账号及权限初步设置'
    ],
    img: 'https://s.huiyunban.cn/1744255683450114048.png',
    left: 1
  },
  {
    h1: <>实施部署——<span style={{color: '#0256FF'}}>实施中</span></>,
    a: [
      '专业培训师现场/线上实施，先易后难循序渐进',
      '指导相关人员亲自操作，保证熟悉各个模块',
      '实施后填写满意度问卷，确保实施质量，并记录实施进度表'
    ],
    img: 'https://s.huiyunban.cn/1744264351054827520.png',
    left: 0
  },
  {
    h1: <>实施部署——<span style={{color: '#0256FF'}}>实施后</span></>,
    a: [
      '实施模块的使用效果跟进，确保实施落地质量',
      '定期输出使用数据报表，确保业务价值得以体现',
      '需求实时反馈和解决，确保客户体验的顺畅',
      '定期组织2次培训，确保新员工使用顺畅度和新功能的布达'
    ],
    img: 'https://s.huiyunban.cn/1744260076845142016.png',
    left: 1
  },
  {
    h1: <>客户<span style={{color: '#0256FF'}}>成功</span></>,
    a: [
      '「技术支持」专属项目团队全程跟进，保证数据安全稳定',
      '「需求支持」1对1项目经理对接，记录和反馈客户每一个业务需求，保障体验',
      '「数据支持」产品关键使用数据按需整理，支持企业精细化劳动力管理',
    ],
    img: 'https://s.huiyunban.cn/1744260605990146048.png',
    left: 0
  },
]