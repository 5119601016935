export const  Resourcei = [
  {
    a: '运营顾问',
    img: 'https://s.huiyunban.cn/1744173088892784640.png',
    b: [
      '运营诊断',
      '经营数据随时调用',
      '关键节点主动提醒',
      '关键指标预测',
      '业务问题建议',
      '智能数据预警'
    ]
  },
  {
    a: '经营帮手',
    img: 'https://s.huiyunban.cn/1744180648660176896.png',
    b: [
      '店长问题库',
      '智能客户助手',
      '日常操作指令',
      '排班结果预测',
      '营业额预测',
      '门店数据预警',
    ]
  },
  {
    a: '员工导师',
    img: 'https://s.huiyunban.cn/1744181237037142016.png',
    b: [
      '语音给班',
      '打卡提醒',
      '班次提醒',
      '工时提醒',
      '职场导师',
      '规划建议',
    ]
  }
];

export const Resourceii = [
  {
    a: '智能查询',
    b: '可通过简单的指令，查询员工、设置、数据等任意信息',
    c: ['1. 设定指定日期，为员工办理提前转', '2. 批量调整员工今天的排班时间', '3. 查询门店人时趋势分析']
  },
  {
    a: '主动提醒',
    b: '自主设置提醒节点，关心的数据可即时推送',
    c: ['1. 排班工时未更新提醒', '2. 人力成本占比高于22%提醒', '3. 离职率超过10%提醒']
  },
  {
    a: '业务诊断',
    b: '协助分析业务健康度，并给出专家级建议',
    c: ['1. 排班合理性建议', '2. 员工满意度建议', '3. 运营状况提升建议']
  },
  {
    a: '操作助手',
    b: '发送指令，快速精准地为您执行操作',
    c: ['1. 设定指定日期，为员工办理提前转正', '2. 批量调整员工今天的排班时间', '3. 提升员工某项通岗技能']
  },
  {
    a: '职场导师',
    b: '为员工提供职业生涯建议和指导',
    c: ['1. 排班工时未更新提醒', '2. 人力成本占比高于22%提醒', '3. 离职率超过10%提醒']
  },
  {
    a: '快速操作',
    b: '使用语音，让AI快速为员工进行操作',
    c: ['1. 语音给班，快速提供可排日期', '2. 快捷查看本周已排班次', '3. 快速确认考勤工时']
  },
];